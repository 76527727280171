import DataTable from 'react-data-table-component'
import { columns } from './table.data'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, useCallback } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import debounce from 'lodash/debounce'
import moment from 'moment'
import {
  getAllActivitiesAction,
  handleLimitChange,
  handlePageChange
} from '../../redux/activity/activityAction'
import CustomPagination from '../../components/pagination/ReactPagination'
import CustomSpinner from '@spinner'
import NotFound from '../../components/not-found/NotFound'
import ActivityHeader from '../../components/screen.components/activity.screen/activityHeader/activityHeader'
const ExpandedComponent = ({ data }) =>
  data?.events.map((event, index) => {
    if (index === 0) {
      return null
    }
    return (
      <div className="row-container">
        <div className="empty-cell"></div>
        <div style={{ borderRight: '1px solid #f1f0ed' }}></div>
        <div className="cell-container">
          <span>
            {event?.time && moment(event?.time, 'HH:mm').format('h:mm A')}
          </span>
        </div>
        <div className="cell-container">
          <span>{event?.event}</span>
        </div>
      </div>
    )
  })
const Activity = () => {
  const currentDate = moment()
  const today = moment(currentDate).startOf('month').toDate()
  const _30days = moment(currentDate).endOf('month').toDate()
  const [currentPage, setCurrentPage] = useState(0)
  const [search, setSearch] = useState('')
  const [endDate, setEndDate] = useState(_30days)
  const [startDate, setStartDate] = useState(today)
  const [time, setTime] = useState(null)
  const dispatch = useDispatch()
  const { getAllActivities, activityPending } = useSelector(
    (state) => state.activities
  )
  const rows = getAllActivities?.data
  const limit = getAllActivities?.limit
  const count = getAllActivities?.count
  const offset = getAllActivities?.offset
  const PageSize = 10

  const debounceFunc = useCallback(
    debounce((e) => {
      dispatch(
        getAllActivitiesAction({
          offset,
          limit,
          startDate,
          endDate,
          time,
          search: e.target.value,
          callback: () => {}
        })
      )
    }, 600),
    []
  )
  const debounceFunc2 = useCallback(
    debounce((time) => {
      dispatch(
        getAllActivitiesAction({
          offset,
          limit,
          startDate,
          endDate,
          time,
          search,
          callback: () => {}
        })
      )
    }, 600),
    []
  )

  const handleSearchChange = (e) => {
    setSearch(e.target.value)

    debounceFunc(e)
  }
  const onStartDateChangeHandler = useCallback((date) => {
    setStartDate(date[0])
  }, [])
  const onEndDateChangeHandler = useCallback((date) => {
    setEndDate(date[0])
  }, [])
  const onTimeChangeHandler = (time) => {
    setTime(moment(time[0]).format('hh:mm'))
    debounceFunc2(moment(time[0]).format('hh:mm'))
  }
  const handleLimit = (newLimit) => {
    dispatch(
      handleLimitChange({
        oldLimit: limit,
        newLimit,
        startDate,
        endDate,
        time,
        search
      })
    )
    setCurrentPage(0)
  }

  // **   Handle Pagination
  const handlePagination = (page) => {
    const newOffset = page.selected * limit
    dispatch(
      handlePageChange({
        offset: newOffset === 0 ? 0 : newOffset,
        limit,
        startDate,
        endDate,
        time,
        search
      })
    )
    setCurrentPage(() => page.selected)
  }
  useEffect(() => {
    dispatch(
      getAllActivitiesAction({
        offset,
        limit,
        startDate,
        endDate,
        time,
        search,
        callback: () => {}
      })
    )
  }, [endDate])
  return (
    <Card>
      <ActivityHeader
        search={search}
        onSearchChange={handleSearchChange}
        startDate={startDate}
        endDate={endDate}
        onStartDateChangeHandler={onStartDateChangeHandler}
        onEndDateChangeHandler={onEndDateChangeHandler}
        onTimeChangeHandler={onTimeChangeHandler}
      />
      <CardBody className="p-0 ">
        <Row>
          <Col lg="12" className="tableSetting">
            <div className="react-dataTable">
              <DataTable
                pagination
                paginationServer
                customStyles={{
                  expanderButton: {
                    style: {
                      color: 'rgba(0, 0, 0, 0.54)' // Change this to your desired color
                    }
                  }
                }}
                rowsPerPage={PageSize}
                paginationDefaultPage={currentPage}
                progressPending={activityPending}
                progressComponent={<CustomSpinner />}
                noDataComponent={<NotFound />}
                data={rows}
                pointerOnHover
                highlightOnHover
                theme="solarized"
                columns={columns}
                expandableRows={true}
                expandableRowsComponent={ExpandedComponent}
                className="react-dataTable"
                paginationComponent={() =>
                  CustomPagination({
                    limit,
                    handleLimit,
                    currentPage,
                    count,
                    handlePagination,
                    position: true
                  })
                }
              />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
export default Activity
